@if (jsonData) {
<div>
    <div class="d-flex flex-row gap-3 justify-items-left align-items-start flex-wrap my-3">
        @for (template of jsonData; track $index) {
        <div>
            <div class="mobile-frame position-relative" [ngClass]="{'pe-none' : template.id == tempId && showCheckbox}" (click)="getSelectedTemp(template)">
                @if(template.id == tempId && showCheckbox){
                    <div class="position-absolute end-0 z-2 d-flex justify-content-center border-none">
                        <div class="badge text-bg-primary text-wrap text-white" style="width: 6rem;">
                            Selected
                        </div>
                    </div>
                }
                <!-- Chat Area -->
                <div class="chat-container">
                    <div class="d-flex align-items-start position-relative">
                        <div class="chat-bubble received-message">
                            @for (component of template.components; track $index) {
                            <span class="chat-triangle"></span>
                            <div>

                                <!-- Chat Header -->
                                @if (component.type === 'HEADER') {
                                <div>
                                    <div [innerHTML]="component.text" class="fw-bold mb-1 message-header"></div>
                                    @if (component.format === 'IMAGE') {
                                    <img [src]="component.example.header_handle[0]" alt="Image" class="img-fluid whatsapp-img" />
                                    }
                                    @else if (component.format === 'DOCUMENT') {
                                        <div class="icon-container d-flex justify-content-center my-3">
                                            <a [href]="component.example?.header_handle[0]" target="_blank" class="pdf-icon-link">
                                                <i class="fas fa-file-alt fa-3x" aria-hidden="true"></i>
                                            </a>
                                        </div>                             
                                    }
                                    @else if (component.format === 'VIDEO') {
                                    <video controls class="video-fluid">
                                        <source [src]="component.example.header_handle[0]" type="video/mp4">
                                    </video>
                                    }
                                    @else if (component.format === 'LOCATION') {
                                    <div class="icon-container d-flex justify-content-center my-3">
                                        <a [href]="component.example?.header_handle[0]" target="_blank" class="location-icon-link">
                                            <i class="fas fa-map-marker-alt fa-3x" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                    }
                                </div>
                                }

                                <!-- Chat Body -->
                                @if (component.type === 'BODY') {
                                <div>
                                    <div [innerHTML]="replacePlaceholders(component.text, component.example?.body_text?.[0] || [])"
                                        class="mb-1 message-body"></div>
                                </div>
                                }

                                <!-- Chat Footer -->
                                @if (component.type === 'FOOTER') {
                                <div>
                                    <div [innerHTML]="component.text" class="mb-1 message-footer"></div>
                                </div>
                                }

                                <!-- Chat Buttons -->
                                @if (component.type === 'BUTTONS') {
                                <div>
                                    <hr>
                                    @for(button of component.buttons; track $index){
                                        <div class="d-flex justify-content-center mb-2">
                                            <a [href]="button.url" target="_blank" class="template-link text-decoration-none">
                                                <div [innerHTML]="replacePlaceholders(button.text, button.example?.body_text?.[0] || [])">
                                                </div>
                                            </a>
                                        </div>
                                    }
                                </div>
                                }

                            </div>
                            }

                        </div>
                    </div>
                </div>

            </div>
        </div>
        }
    </div>
</div>
}