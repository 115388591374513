import { Component } from '@angular/core';
import { GlobalSpinnerService } from '../../service/global-spinner.service';

@Component({
  selector: 'global-spinner',
  template: `
      @if(this.globalSpinnerService.globalSpinnerSignal()){
        <div class="global-spinner d-flex flex-column align-items-center justify-items-center">
          <div class="spinner-grow text-primary" style="width: 3rem; height: 3rem;" role="status">
          </div>
          <span>Please wait</span>
        </div>
      }
  `,
  styleUrl: './global-spinner.component.scss'
})
export class GlobalSpinnerComponent {
  constructor(public globalSpinnerService: GlobalSpinnerService) { }

  ngOnInit(): void {
  }
}
