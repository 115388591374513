import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'whatsapp-templates',
  templateUrl: './whatsapp-templates.component.html',
  styleUrl: './whatsapp-templates.component.scss'
})
export class WhatsappTemplatesComponent {
  @Input() jsonData: any;
  @Input() showCheckbox: boolean = false;
  @Output() templateData = new EventEmitter<any>();

  tempId: any

  ngOnChanges(changes: SimpleChanges) {
    if (changes['jsonData']?.currentValue) {
      console.log('Received jsonData:', this.jsonData);
    }
  }

  getSelectedTemp(selectedTem: any){
    this.tempId = selectedTem.id;
    this.templateData.emit(selectedTem)
  }

  replacePlaceholders(text: string, example: string[]): string {
    return example.reduce((formattedText, value, index) => 
      formattedText
        .replace(`{{${index + 1}}}`, value) // Replace placeholders
        .replace(/\n/g, '<br>') // Replace newlines
        .replace(/\*(.*?)\*/g, '<strong>$1</strong>') // Bold
        .replace(/_(.*?)_/g, '<em>$1</em>'), // Italic
      text
    );
  }
}