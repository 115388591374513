import { Component, EventEmitter, Input, Output } from '@angular/core';
import code from '../../../../assets/json/CountryCodes.json';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'country-code',
  template: `
  <div [formGroup]='parentForm'>
    <ng-select [items]="codeList"
    [clearable]="false"
      [searchFn]="customSearchFn"
      [(ngModel)]="selectedCountryCode"
      [bindLabel]="'name'"
      [formControlName]="name"
      bindValue="dial_code"
      (change)="onSelectCode($event)">
      <ng-template ng-label-tmp let-item="item">
        {{ item?.dial_code }}
      </ng-template>
    </ng-select>
    <div class="invalid-feedback">
      {{ errorMessage }}
    </div>
  </div>
  `,
  styleUrl: './country-code.component.scss'
})
export class CountryCodeComponent {

  codeList = code;
  @Input() selectedCountryCode: any;
  @Input() name: any = [];
  @Input() parentForm!: FormGroup;
  @Input() errorMessage: string = 'Required';
  @Output() onCountryCode: EventEmitter<string> = new EventEmitter<string>;

  ngOnInit(){
  
  }

  onSelectCode(event: any){
    this.onCountryCode.emit(event)
  }

  customSearchFn(term: string, item: any) {
    item.name = item.name.replace(',','');
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }
}
