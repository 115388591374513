import { Component, Input, SimpleChanges } from '@angular/core';
import { ErrorInterface } from '../../service/error-handling.service';

@Component({
  selector: 'error-message',
  template: `
  @if(errorList && errorList.length > 0){
    <div>
      @for(error of errorList; track $index){
        <div class="justify-content-end error-message">
          <div class="alert alert-danger">
            <ul class="list-unstyled m-0">
              <li class="fw-bold">{{error.title}}</li>
              <li><span>{{error.message}}</span></li>
              @if(error.apiSource){
                <li>
                  <small>| {{error.apiSource.name}} Source: {{error.apiSource.endpoint}} </small>
                  @if(error.code && error.code !== null){<small>| Code: {{error.code}}</small>}
                </li>
              }
            </ul>
          </div>
        </div>
      }
    </div>
  }
  `,
})
export class ErrorMessageComponent {
  @Input() errorList: ErrorInterface[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['errorList']?.currentValue) {
    }
  }

}