import { NgModule } from "@angular/core";
import { GlobalSpinnerComponent } from "./controls/global-spinner/global-spinner.component";
import { ErrorMessageComponent } from "./controls/error-message/error-message.component";
import { WhatsappTemplatesComponent } from "./controls/whatsapp-templates/whatsapp-templates.component";
import { CommonModule } from "@angular/common";
import { CountryCodeComponent } from "./controls/country-code/country-code.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    imports:[CommonModule, NgSelectModule, ReactiveFormsModule],
    declarations: [GlobalSpinnerComponent,ErrorMessageComponent,WhatsappTemplatesComponent, CountryCodeComponent],
    exports: [GlobalSpinnerComponent,ErrorMessageComponent,WhatsappTemplatesComponent, CountryCodeComponent]

})

export class CoreModule { }